import React, { useState } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Lightbox from 'react-image-lightbox';


const Slide = styled.div`
    color: white !important;
    height: 350px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    background: $orange;
    transition: 0.3s background-color ease-in-out;
    overflow: hidden;

    .content {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        padding: 0 20px;
        width: 100%;
        height: 100%;
        background-color: rgba(1,1,1,0.5);

        &:hover {
            background-color: rgba(1,1,1,0.1);
            transition: 0.3s background-color ease-in-out;   
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &:hover {
        background-color: rgba(1,1,1,0.2);
        transition: 0.3s background-color ease-in-out;   
    }

    p {        
        font-size: 26px !important;
        font-style: normal; 
        font-weight: 600;
        text-transform: uppercase;
    }
`;

export default () => {

    const [isLigthboxOpen, setIsLightboxOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const data = useStaticQuery(graphql`
        query ListOfBusinesses {
            step1: file(relativePath: {eq: "businesses/restaurants.jpeg"}) {
                childImageSharp {
                    fixed {
                        originalName
                        src
                    }
                }
            }
            step2: file(relativePath: {eq: "businesses/hospitals.jpeg"}) {
                childImageSharp {
                    fixed {
                        originalName
                        src
                    }
                }
            }
            step3: file(relativePath: {eq: "businesses/hairdressers.jpeg"}) {
                childImageSharp {
                    fixed {
                        originalName
                        src
                    }
                }
            }
            step4: file(relativePath: {eq: "businesses/sewers.jpeg"}) {
                childImageSharp {
                    fixed {
                        originalName
                        src
                    }
                }
            }
            step5: file(relativePath: {eq: "businesses/clients.jpeg"}) {
                childImageSharp {
                    fixed {
                        originalName
                        src
                    }
                }
            }
            step6: file(relativePath: {eq: "businesses/gardens.jpeg"}) {
                childImageSharp {
                    fixed {
                        originalName
                        src
                    }
                }
            }
        }
    `);

    const imageList = [
        data.step1.childImageSharp.fixed.src,
        data.step2.childImageSharp.fixed.src,
        data.step3.childImageSharp.fixed.src,
        data.step4.childImageSharp.fixed.src,
        data.step6.childImageSharp.fixed.src,
        data.step5.childImageSharp.fixed.src
    ];

    return <>
        <div className="">
            <div className="row d-flex justify-content-center px-0 mx-0">
                {imageList.map((image, index) => {
                    return (
                        <Slide
                            className='col-12 col-sm-6 col-lg-6 col-xl-4 px-0 mx-0'
                            key={index}
                            onClick={() => {
                                setIsLightboxOpen(true);
                                setPhotoIndex(index);
                            }}
                        >
                            <img src={image} alt={'Бизнес'} />
                            <div className="content">
                                {/* <p className="mb-0">{el}</p> */}
                            </div>
                        </Slide>
                    );
                })}
            </div>
        </div>

        {isLigthboxOpen && (
            <>
                <Lightbox
                    mainSrc={imageList[photoIndex]}
                    nextSrc={imageList[(photoIndex + 1) % imageList.length]}
                    prevSrc={imageList[(photoIndex + imageList.length - 1) % imageList.length]}
                    onCloseRequest={() => { setIsLightboxOpen(false) }}
                    onMovePrevRequest={() => {
                        setPhotoIndex((photoIndex + imageList.length - 1) % imageList.length);
                    }}
                    onMoveNextRequest={() => {
                        setPhotoIndex((photoIndex + 1) % imageList.length);
                    }}
                />
            </>
        )}
    </>
}