import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import Divider from "./divider";

export default () => {
    const data = useStaticQuery(graphql`
        query WayOfWork {
            step1: file(relativePath: {eq: "index/step1.png"}) {
                childImageSharp {
                    fixed {
                        originalName
                        src
                    }
                }
            }
            step2: file(relativePath: {eq: "index/step2.png"}) {
                childImageSharp {
                    fixed {
                        originalName
                        src
                    }
                }
            }
            step3: file(relativePath: {eq: "index/step3.png"}) {
                childImageSharp {
                    fixed {
                        originalName
                        src
                    }
                }
            }
            step4: file(relativePath: {eq: "index/step4.png"}) {
                childImageSharp {
                    fixed {
                        originalName
                        src
                    }
                }
            }
        }
    `);

    return (
        <>
            <section id="way-of-work">
                <Container className="text-center py-5">
                    <h2 className="mt-3">Начин на работа</h2>
                    <Divider addClassName="gold" />
                    <Row className="d-flex justify-content-center mb-5">
                        <Col xs={12} sm={6} md={4} lg={3} className="px-2 my-4 text-center">
                            <img src={data.step1.childImageSharp.fixed.src} alt="Step 1" className="img-fluid mb-3 way-of-work-image" />
                            <h5 className="way-of-work">Стъпка 1</h5>
                            <p className="way-of-work text-black-50">Консултиране</p>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} className="px-2 my-4 text-center">
                            <img src={data.step2.childImageSharp.fixed.src} alt="Step 2" className="img-fluid mb-3 way-of-work-image" />
                            <h5 className="way-of-work">Стъпка 2</h5>
                            <p className="way-of-work text-black-50">Установяване на проблема</p>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} className="px-2 my-4 text-center">
                            <img src={data.step3.childImageSharp.fixed.src} alt="Step 3" className="img-fluid mb-3 way-of-work-image" />
                            <h5 className="way-of-work">Стъпка 3</h5>
                            <p className="way-of-work text-black-50">Обслужване в ателието или на адрес</p>
                        </Col>

                        <Col xs={12} sm={6} md={4} lg={3} className="px-2 my-4 text-center">
                            <img src={data.step4.childImageSharp.fixed.src} alt="Step 4" className="img-fluid mb-3 way-of-work-image" />
                            <h5 className="way-of-work">Стъпка 4</h5>
                            <p className="way-of-work text-black-50">Разрешаване на проблема</p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}