import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row } from 'react-bootstrap';
import constants from "../../utils/constants";
import FullWidthImageSection from "../index/FullWidthImageSection";

const FullWidthCta = ({ bgImage, title, ctaBtnText }) => {

    const data = useStaticQuery(graphql`
    query FullWidthCTAQuery {
        paralaxImage: file(relativePath: {eq: "index/paralax.png"}) {
            childImageSharp {
                fluid(quality: 100) {
                    src
                }
            }
        }
    }`);



    const img = bgImage || data.paralaxImage.childImageSharp.fluid.src;

    return (
        <>
            <FullWidthImageSection
                paralax={false}
                bgImage={img}
            >
                <Row style={{ minHeight: 400 }}>
                    <Container className="text-center d-flex justify-content-center align-items-center flex-column text-white">
                        <h3 className="text-gold mb-4">{title}</h3>
                        <a href={`tel:${constants.TELEPHONE}`} className="btn btn-primary">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-telephone-forward-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.761.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
                            </svg>
                            <span className="ml-3">{ctaBtnText}</span>
                        </a>
                    </Container>
                </Row>
            </FullWidthImageSection>
        </>
    );
};

export default FullWidthCta;

FullWidthCta.defaultProps = {
    title: 'Потърси ни при спешен случай!',
    ctaBtnText: 'Обади се'
}

FullWidthCta.propTypes = {
    title: PropTypes.string,
    ctaBtnText: PropTypes.string
}