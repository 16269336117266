import React from 'react';
import styled from 'styled-components';

const ChevronWrapper = styled.div`
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translate(-50%, 0);
    cursor: pointer;
    z-index: 10;
    transition: 0.3s bottom ease-in-out;

    svg {
        fill: white;
    }

    &:hover {
        bottom: 45px;
        transition: 0.3s bottom ease-in-out;
    }

`;

export default () => (
    <ChevronWrapper>
        <a href="#chevron" className="chevron-down" id="chevron">
            <svg width="34px" height="34px" viewBox="0 0 16 16" className="bi bi-chevron-compact-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67z" />
            </svg>
        </a>
    </ChevronWrapper>
)